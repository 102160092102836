@media only screen and (max-width: 600px) {
	main {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.intercom-lightweight-app-launcher,
	.intercom-launcher-frame {
		bottom: 70px !important;
	}
}

main {
	overflow: hidden !important;
	max-width: 100% !important;
}

input[type='file' i] {
	display: none;
}

@media all and (max-width: 960px) {
	.MuiAutocomplete-option {
		font-size: 0.9rem;
		min-height: 0 !important;
	}
}

@media all and (max-width: 600px) {
	.MuiAutocomplete-option {
		font-size: 0.7rem;
	}
}
